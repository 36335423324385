import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const ProjectHelse = ({ data, location }) => {
  return (
    <Layout>
      <SEO title="Tenias - Desarrollo de web corporativa con buscador personalizado" description="Tenias es un fabricante de palas cargadoras para más de 3000 referencias de tractor, desde la web los usuarios pueden consultar la pala para su modelo de tractor."/>
      <section className="jumbotron pt-3 pb-6 mb-0" style={{backgroundColor: '#FFEF99'}}>
        <div className="hero-zone container">
          <div className="hero-text row justify-content-md-center">
            <div className="col-lg-6">
              <h1 className="font-weight-bold diplay-4">Tenías</h1>
              <p className="h3">Desarrollo de sitio web en Drupal 8 con buscador entre más de 3000 referencias de tractores.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-3 bg-light text-left" id="features">
        <div className="container pb-5">
          <div className="row text-left">
            <div className="col-md-8 col-sm-12">
              <span className="badge badge-pill badge-secondary p-2 mr-2">Desarrollo Drupal</span>
              <span className="badge badge-pill badge-secondary p-2 m-2">Migración SEO</span>
              <span className="badge badge-pill badge-secondary p-2 m-2">Diseño Visual</span>
              <span className="badge badge-pill badge-secondary p-2 m-2 mb-4">UX</span>
              <h2>
                <strong className="text-left pt-3">Puntos clave</strong>
              </h2>
              <div className="lead pt-4">
                <strong className="texto-destacado">Buscador de tractores predictivo.</strong>
                <p>Este cliente fabrica palas cargadoras para más de 3000 modelos de tractores, y el usuario debe ser capaz de encontrar fácilmente su modelo de tractor, y ver las características e imágenes de la pala cargadora para ese modelo.</p>
              </div>
              <div className="lead pt-4">
                <strong className="texto-destacado">Volcado de datos.</strong>
                <p>Uno de los mayores retos de este proyecto era la integración en la web de todas las marcas, modelos y series de tractor, con el modelo de pala cargadora que aplica a cada una de esas variaciones. Los datos originales estaban almacenados en catálogos que tuvieron que se transformados en información estructurada antes de desarrollar una migración automatizada a Drupal, con sus respectivos atributos.</p>
              </div>
              <div className="lead pt-4">
                <strong className="texto-destacado">Jerarquía de carga de recursos gráficos.</strong>
                <p>Era inviable tener recursos gráficos para cada variante de pala/tractor, así que desarrollamos una gestión de imágenes jerarquizada, para cargar la imagen más relevante para cada modelo de tractor.</p>
              </div>
              <div className="lead pt-4">
                <strong className="texto-destacado">Contenido variable para cada país.</strong>
                <p>Como este cliente exporta a todo el mundo, nos pidieron adaptar el contenido de la página según la localización del usuario. Para llevar a cabo esto utilizamos un servicio de localización para asegurar la máxima precisión y adaptar los productos y distribuidores al país desde el que se accede a la página.</p>
              </div>
              <div className="lead pt-4 pb-5">
                <strong className="texto-destacado">Multilenguaje.</strong>
                <p>Esta web soporta multilenguaje con URLs SEO-friendly para inglés, francés y castellano.</p>
              </div>
            </div>
            <div className="col-md-3 offset-md-0 offset-lg-1 col-sm-12">
              <div className="colaboradores">
                <h2 className="pt-lg-5">
                  <strong className="text-left pt-0">Colaboradores</strong>
                </h2>
              </div>
              <p className="pt-3 client-desc">En este proyecto colaboramos con <a href="https:detalier.com/" target="_blank" rel="noopener noreferrer">Detalier</a>, que hicieron la conceptualización del proyecto y toda la parte visual (Diseño visual y UX)</p>
              <div className="cliente-proyecto">
                <h2 className="pt-5">
                  <strong className="text-left pt-0">Cliente</strong>
                </h2>
              </div>
              <p className="pt-3 client-desc">Fabricante de palas cargadoras, implementos agrícolas y cosechadoras. Exporta sus productos a todo el mundo.</p>
              <div className="btn-project">
                <a className="btn mr-4 btn-lg btn-outline-primary" href="https://tenias.com/">Sitio web →</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="jumbotron pb-5 text-left bg-white pt-0 mt-n6" id="features">
        <div className="container">
          <div className="row text-left">
            <div className="col-md-12 col-12 col-sm-12">
              <h2 className="pt-4 pb-4">
                <strong>El proyecto en imágenes</strong>
              </h2>
            </div>
            <div className='embed-responsive embed-responsive-16by9'>
               <iframe className="project-video embed-responsive-item" title="Video" width={1200} height={674} src="https://player.vimeo.com/video/412514482?autoplay=1&loop=1&autopause=0&muted=1&background=1"></iframe>
            </div>
          </div>
        </div>
      </section>

    </Layout>
  )
}

export default ProjectHelse
